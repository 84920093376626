/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'

export default (props) => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					description
					title
					lang
				}
			}
		}
	`)

	// ページタイトルが指定されている場合は、「ページごとのタイトル|サイト名」で表示させる
	const title = props.pagetitle
		? `${props.pagetitle}|${data.site.siteMetadata.title}`
		: data.site.siteMetadata.title
	const description = props.pageDesc || data.site.siteMetadata.description

	return (
		<Helmet>
			<html lang={data.site.siteMetadata.lang} />
			<title>{title}</title>
			<meta name='description' content={description} />
			<meta name='robots' context={props.noIndexToggle ? 'noindex' : 'index'} />
		</Helmet>
	)
}
